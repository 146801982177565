import firebase from "firebase/app";
import "firebase/database";
import "firebase/functions";

var firebaseConfig = {
  apiKey: "AIzaSyC1HO1SeKHjt7RdVaR4ykrVDbnrIWiQO88",
  authDomain: "colorgecko-9fd24.firebaseapp.com",
  databaseURL: "https://colorgecko-9fd24.firebaseio.com",
  projectId: "colorgecko-9fd24",
  storageBucket: "colorgecko-9fd24.appspot.com",
  messagingSenderId: "89353476567",
  appId: "1:89353476567:web:eea38e4b81cb2200549392",
};

firebase.initializeApp(firebaseConfig);

export default firebase;

export const database = firebase.database();
