import React, { useState } from "react";
import { TextField, Radio, Button } from "rmwc";
import { startNewGame } from "./databaseActions";
import { randomGameName } from "./utils";
import "./GameInit.css";

const GameInit = () => {
  let location = window.location.pathname.replace(/\//gi, "");

  const [numPlayers, setNumPlayers] = useState("3");
  const [tempName, setTempName] = useState(randomGameName);
  const [gameName, setGameName] = useState(location);

  const changeGameName = () => {
    setGameName(tempName);
    window.location.pathname = tempName;
  };

  const handleGameConfig = () => {
    startNewGame(gameName, numPlayers);
  };

  const handlePlayerChange = (evt) => {
    const val = evt.currentTarget.value;
    setNumPlayers(val);
  };

  const handleNameChange = (evt) => {
    const name = evt.currentTarget.value.replace(/[^a-zA-Z0-9]/gi, "");
    setTempName(name);
  };

  return (
    <div className="config-container">
      <h3>Configure your game</h3>
      {!gameName && (
        <>
          <div>
            Play ColorGecko online with friends. To create a new game or join an
            existing game, enter a game identifier.
          </div>
          <form
            onSubmit={(evt) => {
              evt.preventDefault();
              changeGameName();
            }}
          >
            <TextField
              className="input-name"
              label="Game identifier"
              maxLength={20}
              value={tempName}
              onChange={handleNameChange}
            />
            <div>
              <Button raised label="Next" disabled={tempName === ""} />
            </div>
          </form>
          <div style={{ marginTop: "20px" }}>
            Share this link with your friends:
          </div>
          <div>
            <input
              id="copy"
              readOnly
              value={window.location.href + tempName}
              style={{ marginTop: "10px", width: "50%" }}
            />
          </div>
          <button
            style={{ marginTop: "10px" }}
            onClick={() => {
              var copyText = document.querySelector("#copy");
              copyText.select();
              document.execCommand("copy");
            }}
          >
            Click to copy URL
          </button>
        </>
      )}
      {gameName && (
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            handleGameConfig();
          }}
        >
          <div className="radio-label">Number of players</div>
          <div>
            <Radio
              label="2"
              value="2"
              checked={numPlayers === "2"}
              onChange={handlePlayerChange}
            />
            <Radio
              label="3"
              value="3"
              checked={numPlayers === "3"}
              onChange={handlePlayerChange}
            />
            <Radio
              label="4"
              value="4"
              checked={numPlayers === "4"}
              onChange={handlePlayerChange}
            />
            <Radio
              label="5"
              value="5"
              checked={numPlayers === "5"}
              onChange={handlePlayerChange}
            />
          </div>
          <div>
            <Button raised label="Start" />
          </div>
          {numPlayers === "2" && (
            <ul style={{ marginTop: "20px", textAlign: "left" }}>
              <li>2 player games are played with only 5 colors.</li>
              <li>
                There is also 3 rows for card placement but they are limited to
                3, 2, 1 one card each.
              </li>
            </ul>
          )}
          {numPlayers === "3" && (
            <ul style={{ marginTop: "20px", textAlign: "left" }}>
              <li>3 player games are played with only 6 colors.</li>
              <li>
                There will be 3 rows for card placement each with a limit of 3
                cards.
              </li>
            </ul>
          )}
          {numPlayers === "4" && (
            <ul style={{ marginTop: "20px", textAlign: "left" }}>
              <li>4 player games are played with 7 colors.</li>
              <li>
                There will be 4 rows for card placement each with a limit of 3
                cards.
              </li>
            </ul>
          )}
          {numPlayers === "5" && (
            <ul style={{ marginTop: "20px", textAlign: "left" }}>
              <li>5 player games are played with 7 colors.</li>
              <li>
                There will be 5 rows for card placement each with a limit of 3
                cards.
              </li>
            </ul>
          )}
        </form>
      )}
    </div>
  );
};

export default GameInit;
