import React, { useState } from "react";
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
  TopAppBarFixedAdjust,
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
  Tab,
  TabBar,
} from "rmwc";
import { startNewGame } from "./databaseActions";
import "./AppHeader.css";

const AppHeader = ({ numPlayers, setNumPlayers }) => {
  const gameName = window.location.pathname.replace(/\//gi, "");

  const [openConfirmNewGame, setOpenConfirmNewGame] = useState(false);
  const [openConfirmRestartGame, setOpenConfirmRestartGame] = useState(false);
  const [openRules, setOpenRules] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const overviewText = (
    <>
      <p>
        <span style={{ fontWeight: "bold" }}>ColorGecko</span> is similar to{" "}
        <a
          href="https://en.wikipedia.org/wiki/Coloretto"
          target="_blank"
          rel="noopener noreferrer"
        >
          Coloretto
        </a>
        .
      </p>
      <p>
        &#8594; Watch a{" "}
        <a
          href="https://youtu.be/uFzniA0ovFY"
          target="_blank"
          rel="noopener noreferrer"
        >
          Youtube video
        </a>{" "}
        explanation on how to play that game.
      </p>
      <p>
        ColorGecko is a card game where players collect cards from as few colors
        as possible, because only cards from 3 colors will count towards your
        final score; the rest count against it.
      </p>
      <p>
        Players take turns drawing a card from the supply and placing them
        within rows. Rows contain at most 3 cards. Instead of placing a card in
        the row, a player can decide to take a row and place the cards in their
        hand. Doing so will end their turn for this round; ending once each
        player has selected a row.
      </p>
      <p>
        Once the supply drops to 15 cards, the round is declared to be the last
        round. Scoring is calculated and finalized at the end of the last round.
        The player with the most points when the final round ends wins the game!
      </p>
      <p>
        There are 9 cards in each of 7 colors, 3 wild cards, and 10 "+2" cards
        that give the player 2 points. In a 2 player game, only 5 colors are
        used for game play. In a 3 player, player game only 6 colors are used.
      </p>
    </>
  );
  const scoringText = (
    <>
      <table className="scoring-table">
        <tr>
          <th>Cards</th>
          <th>Value for set</th>
        </tr>
        <tr>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <td>2</td>
          <td>3</td>
        </tr>
        <tr>
          <td>3</td>
          <td>6</td>
        </tr>
        <tr>
          <td>4</td>
          <td>10</td>
        </tr>
        <tr>
          <td>5</td>
          <td>15</td>
        </tr>
        <tr>
          <td>6 or more</td>
          <td>21</td>
        </tr>
        <tr>
          <td>Each "+2"</td>
          <td>2</td>
        </tr>
      </table>
      <p>
        To score a hand, players add up the points for 3 colors, placing wild
        cards with a color set to maximize their score, and adding any "+2"
        cards to the sum. The rest of the cards are counted against their score.
      </p>
      <p>
        Each color set gives more points as the set grows, to a maximym of 21
        pts for a set of 6 or more. When counting cards from the extra colors,
        the value to be subtracted from the score is also calculated by sets
        using the following table.
      </p>
      <h4>Example scoring</h4>
      <div className="scoring-example">
        <p>The following hand is worth 41 points.</p>
        <div className="stacked-set">
          <div className="value">+21 pts</div>
          <div className="small-card green" style={{ top: "20px" }}></div>
          <div className="small-card green" style={{ top: "30px" }}></div>
          <div className="small-card green" style={{ top: "40px" }}></div>
          <div className="small-card green" style={{ top: "50px" }}></div>
          <div className="small-card green" style={{ top: "60px" }}></div>
          <div className="small-card green" style={{ top: "70px" }}></div>
        </div>
        <div className="stacked-set">
          <div className="value">+15 pts</div>
          <div className="small-card yellow" style={{ top: "20px" }}></div>
          <div className="small-card yellow" style={{ top: "30px" }}></div>
          <div className="small-card yellow" style={{ top: "40px" }}></div>
          <div className="small-card yellow" style={{ top: "50px" }}></div>
          <div className="small-card wild" style={{ top: "60px" }}></div>
        </div>
        <div className="stacked-set">
          <div className="value">+6 pts</div>
          <div className="small-card pink" style={{ top: "20px" }}></div>
          <div className="small-card pink" style={{ top: "30px" }}></div>
          <div className="small-card pink" style={{ top: "40px" }}></div>
        </div>
        <div className="stacked-set">
          <div className="value">-3 pts</div>
          <div className="small-card blue" style={{ top: "20px" }}></div>
          <div className="small-card blue" style={{ top: "30px" }}></div>
        </div>
        <div className="stacked-set">
          <div className="value">+2 pts</div>
          <div className="small-card plus2" style={{ top: "20px" }}>
            +2
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Dialog
        open={openConfirmNewGame}
        onClose={(evt) => {
          if (evt.detail.action === "newGame") {
            setNumPlayers(null);
            startNewGame(gameName, null);
          }
          setOpenConfirmNewGame(false);
        }}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>Do you wish to configure a new game?</DialogContent>
        <DialogActions>
          <DialogButton action="cancel">Cancel</DialogButton>
          <DialogButton action="newGame">Yes</DialogButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmRestartGame}
        onClose={(evt) => {
          if (evt.detail.action === "restart") {
            startNewGame(gameName, numPlayers, true);
          }
          setOpenConfirmRestartGame(false);
        }}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          Do you wish to restart the game with same players?
        </DialogContent>
        <DialogActions>
          <DialogButton action="cancel">Cancel</DialogButton>
          <DialogButton action="restart">Yes</DialogButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openRules}
        onClose={() => {
          setOpenRules(false);
        }}
      >
        <DialogTitle>ColorGecko Rules</DialogTitle>
        <DialogContent>
          <TabBar
            activeTabIndex={activeTab}
            onActivate={(evt) => setActiveTab(evt.detail.index)}
            style={{ marginBottom: "10px" }}
          >
            <Tab>Overview</Tab>
            <Tab>Scoring</Tab>
          </TabBar>
          {activeTab === 0 && overviewText}
          {activeTab === 1 && scoringText}
        </DialogContent>
        <DialogActions>
          <DialogButton action="close" isDefaultAction>
            OK
          </DialogButton>
        </DialogActions>
      </Dialog>
      <TopAppBar dense>
        <TopAppBarRow>
          <TopAppBarSection alignStart>
            <TopAppBarTitle>
              <a href="/" style={{ textDecoration: "unset", color: "#FFF" }}>
                ColorGecko
              </a>
            </TopAppBarTitle>
            <Theme use={["primaryBg", "onSecondary"]} wrap>
              <span
                className="topbar-actions"
                onClick={() => setOpenRules(true)}
              >
                Rules
              </span>
            </Theme>
            {numPlayers && (
              <Theme use={["primaryBg", "onSecondary"]} wrap>
                <span
                  className="topbar-actions"
                  onClick={() => setOpenConfirmNewGame(true)}
                >
                  New Game
                </span>
              </Theme>
            )}
            {numPlayers && (
              <Theme use={["primaryBg", "onSecondary"]} wrap>
                <span
                  className="topbar-actions"
                  onClick={() => setOpenConfirmRestartGame(true)}
                >
                  Restart
                </span>
              </Theme>
            )}
          </TopAppBarSection>
          {gameName && (
            <TopAppBarSection alignEnd>
              <div style={{ marginRight: "10px" }}>
                Playing in room: <strong>{gameName}</strong>
              </div>
            </TopAppBarSection>
          )}
        </TopAppBarRow>
      </TopAppBar>
      <TopAppBarFixedAdjust dense />
    </>
  );
};

export default AppHeader;
