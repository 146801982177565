import React, { useState, useEffect } from "react";
import map from "lodash/map";
import {
  Fab,
  Icon,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
} from "rmwc";
import { database } from "./firebase";
import {
  chooseCardFromDeck,
  placeCardInRow,
  takeCardRow,
  startNewGame,
} from "./databaseActions";
import GameInit from "./GameInit";
import Player from "./Player";
import RowHouses from "./RowHouses";
import "./Game.css";

const Game = ({ numPlayers, setNumPlayers }) => {
  const gameName = window.location.pathname.replace(/\//gi, "");
  const dataRef = database.ref(`/games`);

  const [data, setData] = useState(null);
  const [openWinner, setOpenWinner] = useState(false);

  useEffect(() => {
    function onValueChange(snapshot) {
      const newdata = snapshot.val();
      setData(newdata);
      if (newdata) {
        setNumPlayers(newdata.numPlayers);
        if (newdata.status.indexOf("winner") !== -1) {
          setOpenWinner(true);
        }
      }
    }

    if (gameName) {
      dataRef.child(gameName).on("value", onValueChange);
      return () => {
        dataRef.child(gameName).off("value", onValueChange);
      };
    }
  });

  const twoPlayers = numPlayers === 2 ? "two-players" : "";
  const placingCard = data && data.cardToPlace ? "placing-card" : "";
  const playerTurnIndex = data && data.playOrder.split(",")[0];
  const playerTurn = data && data[`player${playerTurnIndex}name`];

  const rows = [];
  let rowsFull = 0;
  if (data) {
    for (let i = 0; i < (numPlayers === 2 ? 3 : numPlayers); i++) {
      const tempRow = data[`row${i + 1}`];
      rows.push(tempRow);
      if (tempRow) {
        let tempArray = tempRow.split(",");
        if (tempArray[tempArray.length - 1] !== "empty") {
          rowsFull++;
        }
      } else {
        rowsFull++;
      }
    }
  }

  let disableTakeCard =
    data &&
    (data.cardToPlace ||
      rowsFull === (data.numPlayers === 2 ? 3 : data.numPlayers));

  const players = [];
  if (data) {
    for (let i = 1; i <= numPlayers; i++) {
      players.push({
        name: data[`player${i}name`],
        score: data[`player${i}score`],
        hand: data[`player${i}hand`],
        status: data[`player${i}status`],
        id: i,
      });
    }
  }

  let winnerText;
  if (
    data &&
    players.length === numPlayers &&
    data.status.indexOf("winner") !== -1
  ) {
    winnerText = "Couldn't determine a winner.";
    const winner = data.status.split(":")[1].split(",");

    if (winner.length === 1) {
      winnerText = `${players[winner[0] - 1].name} (${
        players[winner[0] - 1].score
      }pts) wins!`;
    } else if (winner.length > 1) {
      winnerText = "";

      winner.forEach((win, index) => {
        winnerText +=
          players[win - 1].name + " (" + players[win - 1].score + "pts)";
        if (index < winner.length - 1) {
          winnerText += " & ";
        }
      });

      winnerText += " win!";
    }
  }

  const handleRowClick = (rowid) => {
    const row = data[`row${rowid + 1}`].split(",");

    let rowStatus;
    if (row === "") {
      rowStatus = "unavailable";
    } else if (row[0] === "empty") {
      rowStatus = "empty";
    } else if (row[row.length - 1] === "empty") {
      rowStatus = "available";
    } else {
      rowStatus = "full";
    }

    if (
      data.cardToPlace &&
      (rowStatus === "available" || rowStatus === "empty")
    ) {
      placeCardInRow(gameName, rowid);
    } else if (rowStatus === "available" || rowStatus === "full") {
      if (!data.cardToPlace) takeCardRow(gameName, rowid);
    }
  };

  const pickCardFromDeck = () => {
    let availableRows = false;
    rows.forEach((row) => {
      if (row) {
        const rowValues = row.split(",");
        if (rowValues[rowValues.length - 1] === "empty") {
          //has at least one space
          availableRows = true;
        }
      }
    });

    if (!data.cardToPlace && availableRows) {
      chooseCardFromDeck(gameName);
    }
  };

  return (
    <div className="game-container">
      {!gameName && <GameInit />}
      {gameName && !data && <GameInit />}
      {gameName && data && (
        <>
          <Dialog
            open={openWinner && data.status.indexOf("winner") !== -1}
            onClose={(evt) => {
              setOpenWinner(false);
              if (evt.detail.action === "newGame") {
                startNewGame(gameName, null);
              }
              if (evt.detail.action === "restart") {
                startNewGame(gameName, numPlayers, true);
              }
            }}
          >
            <DialogTitle>Congratulations!</DialogTitle>
            <DialogContent>{winnerText}</DialogContent>
            <DialogActions>
              <DialogButton action="newGame">New Game</DialogButton>
              <DialogButton action="restart" isDefaultAction>
                Restart
              </DialogButton>
            </DialogActions>
          </Dialog>
          <div
            className="players-container"
            style={{ minWidth: "300px", zIndex: "1" }}
          >
            {data && data.status === "newGame" && (
              <div className="new-game">
                <Icon icon={{ icon: "info", size: "xsmall" }} />
                Take some time to rename the players
              </div>
            )}
            {map(players, (player) => (
              <Player
                key={player.id}
                data={player}
                playerTurn={playerTurnIndex}
              />
            ))}
          </div>
          <div
            className={"content-container " + twoPlayers + " " + placingCard}
          >
            <div className="information-container">
              <Tooltip
                content={
                  disableTakeCard
                    ? `Already have a card drawn`
                    : `Select a new card to place in a row`
                }
                showArrow
              >
                <Fab
                  icon="add"
                  label="Card"
                  theme={["primaryBg", "onPrimary"]}
                  style={
                    disableTakeCard && {
                      cursor: "not-allowed",
                      opacity: "0.4",
                    }
                  }
                  onClick={() => pickCardFromDeck()}
                />
              </Tooltip>
              <div className="information">
                {data.playerOrder !== "" && (
                  <div className="next-player">{playerTurn}'s turn</div>
                )}
                {data.status.indexOf("lastRound") !== -1 && (
                  <div className="last-round">Last Round!</div>
                )}
              </div>
            </div>

            <RowHouses
              rows={rows}
              handleRowClick={handleRowClick}
              placeCard={data.cardToPlace}
            />
          </div>
          <div className="chosen-card-container">
            {!data.cardToPlace && (
              <div className="information-action">
                <span>
                  <Icon icon={{ icon: "info", size: "xsmall" }} />
                  Take an action:
                </span>
                <ul>
                  <li>Take new card to place in row.</li>
                  <li>Click row to place cards in hand.</li>
                </ul>
              </div>
            )}
            {data.cardToPlace && (
              <div className="information-action">
                <Icon icon={{ icon: "info", size: "xsmall" }} />
                Click on a row to place the card.
              </div>
            )}
            {data.cardToPlace && (
              <Tooltip content="Place card in an available row">
                <div className={"chosen-card " + data.cardToPlace}></div>
              </Tooltip>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default Game;
