import shuffle from "lodash/shuffle";
import map from "lodash/map";
import { database } from "./firebase";
import { calculateScore } from "./utils";
import baseGameData from "./baseGameData";
const gamesRef = database.ref(`/games`);

export const deleteGameData = (gameName) => {
  gamesRef.child(`${gameName}`).remove();
};

export const startNewGame = (gameName, numPlayers, keepPlayers = false) => {
  if (numPlayers === null) {
    gamesRef.child(`${gameName}`).remove();
    return;
  }

  gamesRef
    .child(`${gameName}`)
    .once("value")
    .then((snapshot) => {
      const gameData = baseGameData[numPlayers];

      if (keepPlayers) {
        for (let i = 0; i < numPlayers; i++) {
          gameData[`player${i + 1}name`] = snapshot
            .child(`player${i + 1}name`)
            .val();
        }
        gameData.status = "";
      } else {
        for (let i = 0; i < numPlayers; i++) {
          gameData[`player${i + 1}name`] = `Player${i + 1}`;
        }
      }

      const cards = {
        wild: 3,
        plus2: 10,
        orange: 9,
        blue: 9,
        brown: 9,
        yellow: 9,
        grey: 9,
        green: 9,
        pink: 9,
      };

      let colors = [
        "orange",
        "blue",
        "brown",
        "yellow",
        "grey",
        "green",
        "pink",
      ];
      colors = shuffle(colors);

      // remove 1 color for 3 player games
      if (numPlayers <= 3) {
        const color = colors.pop();
        cards[color] = 0;
      }
      // remove 2 colors for 2 player games
      if (numPlayers === "2") {
        const color = colors.pop(); // first color removed above
        cards[color] = 0;
      }

      // populate first color for players
      for (let i = 0; i < numPlayers; i++) {
        const color = colors.shift();
        cards[color]--;
        gameData[`player${i + 1}hand`] = `${color}:1`;
      }

      let deck = [];
      map(cards, (card, key) => {
        for (let i = 0; i < card; i++) {
          deck.push(key);
        }
      });

      deck = shuffle(deck);
      gameData.deck = deck.join(",");
      gameData.cardToPlace = "";
      gameData["timestamp"] = Date.now();
      gamesRef.child(`${gameName}`).update(gameData, function (error) {
        if (error) {
          console.error("something went wrong");
        }
      });
    });
};

export const updatePlayerName = (gameName, id, name) => {
  gamesRef.child(`${gameName}/player${id}name`).set(name);
};

export const chooseCardFromDeck = (gameName) => {
  gamesRef
    .child(gameName)
    .once("value")
    .then((snapshot) => {
      const data = snapshot.val();
      const deck = data.deck.split(",");
      const cardToPlace = deck.shift();
      data.status = deck.length <= 15 ? "lastRound" : "";
      data.deck = deck.join(",");
      data.cardToPlace = cardToPlace;
      data.timestamp = Date.now();
      gamesRef.child(gameName).update(data);
    });
};

export const placeCardInRow = (gameName, rowid) => {
  gamesRef
    .child(gameName)
    .once("value")
    .then((snapshot) => {
      const data = snapshot.val();
      const rowArray = data[`row${rowid + 1}`].split(",");
      for (let i = 0; i < rowArray.length; i++) {
        if (rowArray[i] === "empty") {
          rowArray[i] = data.cardToPlace;
          break;
        }
      }
      const playOrder = data.playOrder.split(",");
      const currPlayer = playOrder.shift();
      playOrder.push(currPlayer);
      data.playOrder = playOrder.join(",");

      data[`row${rowid + 1}`] = rowArray.join(",");
      data.cardToPlace = null;
      data.timestamp = Date.now();
      gamesRef.child(gameName).update(data, function (error) {
        if (error) {
          console.error("something went wrong");
        }
      });
    });
};

export const takeCardRow = (gameName, rowid) => {
  gamesRef
    .child(gameName)
    .once("value")
    .then((snapshot) => {
      const data = snapshot.val();
      const rowTaken = data[`row${rowid + 1}`].split(",");
      const playOrder = data.playOrder.split(",");
      const currPlayer = playOrder.shift();
      const playerHand = data[`player${currPlayer}hand`].split(",");
      const playerHandObj = {};
      playerHand.forEach((h) => {
        const [key, value] = h.split(":");
        playerHandObj[key] = value;
      });
      rowTaken.forEach((r) => {
        if (r !== "empty") {
          playerHandObj[r] = playerHandObj[r]
            ? parseInt(playerHandObj[r]) + 1
            : 1;
        }
      });
      const newHandArr = [];
      map(playerHandObj, (value, key) => {
        newHandArr.push(`${key}:${value}`);
      });
      const score = calculateScore(newHandArr.join(","));
      data[`row${rowid + 1}`] = null;

      let emptyRowCount = 0;
      for (let i = 0; i < (data.numPlayers === 2 ? 3 : data.numPlayers); i++) {
        if (!data[`row${i + 1}`]) {
          emptyRowCount++;
        }
      }

      // if(emptyRowCount === data.numPlayers && data.status === "lastRound") {} else
      if (emptyRowCount === data.numPlayers) {
        if (data.numPlayers > 2) {
          for (let i = 0; i < data.numPlayers; i++) {
            data[`row${i + 1}`] = "empty,empty,empty";
          }
        } else {
          data["row1"] = "empty,empty,empty";
          data["row2"] = "empty,empty";
          data["row3"] = "empty";
        }

        let newPlayOrder = [];
        for (let i = 0; i < data.numPlayers; i++) {
          newPlayOrder.push(
            ((parseInt(currPlayer) - 1 + i) % data.numPlayers) + 1
          );
        }
        data.playOrder = newPlayOrder.join(",");
        for (let i = 0; i < data.numPlayers; i++) {
          data[`player${i + 1}status`] = "";
        }
      } else {
        data.playOrder = playOrder.join(",");
        data[`player${currPlayer}status`] = "rowTaken";
      }

      data[`player${currPlayer}score`] = score;
      data[`player${currPlayer}hand`] = newHandArr.join(",");
      // TODO declare winner if it was last round
      if (data.status === "lastRound" && emptyRowCount === data.numPlayers) {
        let highScore = 0;
        let winner = [];
        for (let i = 0; i < data.numPlayers; i++) {
          if (data[`player${i + 1}score`] === highScore) {
            winner.push(i + 1); // hande ties
          } else if (data[`player${i + 1}score`] > highScore) {
            highScore = data[`player${i + 1}score`];
            winner = [];
            winner.push(i + 1);
          }
        }
        data.status = `winner:${winner.join(",")}`;
      }
      data.timestamp = Date.now();
      gamesRef.child(gameName).update(data, function (error) {
        if (error) {
          console.error("something went wrong");
        }
      });
    });
};

/*
export const setGameData = (gameName, keyPath, value) => {
  gamesRef
    .child(`${gameName}/${keyPath}`)
    .update(value, function(error) {
      
    })
};

export const updateGameData = (gameName, keyPath, data) => {
  gamesRef
    .child(`${gameName}/newGame`)
    .once("value")
    .then((snapshot) => {
      if (snapshot.exists()) {
        gamesRef.child(`${gameName}/newGame`).set(null);
      }
    });

  gamesRef.child(`${gameName}/${keyPath}`).update(data, function (error) {
    if (error) {
      console.log("something went wrong");
    }
  });

  gamesRef.child(gameName).child("timestamp").set(Date.now());
};

export const chooseCardFromDeck = (gameName) => {
  gamesRef
    .child(gameName)
    .child("table")
    .once("value")
    .then((snapshot) => {
      let deck = [];
      let cardToPlace = null;
      if (!snapshot.child("cardToPlace").exists()) {
        deck = snapshot.child("deck").toJSON();
        const deckIndex = Object.keys(deck)[0];
        cardToPlace = deck[deckIndex];
        deck[deckIndex] = null;
      }
      if (cardToPlace) {
        gamesRef.child(gameName).child("table").child("deck").update(deck);
        gamesRef.child(gameName).child("table").update({ cardToPlace });
      }
    });
};

export const placeCardInRow = (gameName, rowid) => {
  gamesRef
    .child(gameName)
    .child("table")
    .once("value")
    .then((snapshot) => {
      let cardToPlace = snapshot.child("cardToPlace").val();
      let row = snapshot.child("rows").child(rowid);
      let cardSlotId = -1;
      let nextCardSlotId = -1;
      row.child("cards").forEach((card) => {
        // get card slot id of next empty slot
        //also grab next empty slot to determine if row is full or not after placing
        if (card.val() === "empty" && cardSlotId === -1) {
          cardSlotId = card.key;
        } else if (
          card.val() === "empty" &&
          cardSlotId !== -1 &&
          nextCardSlotId === -1
        ) {
          nextCardSlotId = card.key;
        }
      });

      // validate that we can place a card
      if (cardToPlace && cardSlotId !== -1) {
        if (nextCardSlotId === -1) {
          setGameData(gameName, `table/rows/${rowid}/status`, "full");
        } else {
          setGameData(gameName, `table/rows/${rowid}/status`, "available");
        }

        setGameData(
          gameName,
          `table/rows/${rowid}/cards/${cardSlotId}`,
          cardToPlace
        );
        setGameData(gameName, "table/cardToPlace", null);

        // if 15 cards or less, declare last round
        if (Object.keys(snapshot.child("deck").val()).length <= 15) {
          setGameData(gameName, "lastRound", true);
        }
      }
    });
};

export const takeCardRow = (gameName, rowId) => {
  gamesRef
    .child(gameName)
    .once("value")
    .then((snapshot) => {
      const row = snapshot.child(`table/rows/${rowId}`).val();
      const currPlayer = snapshot.child("playerOrder").val().split(",")[0];
      const playerHand = snapshot.child(`players/${currPlayer}/hand`).val();

      // validate that row has at least 1 card, the row is available, and that we aren't placing a card
      if (
        !snapshot.child("table/cardToPlace").exists() &&
        row.cards[0] !== "empty" &&
        row.status !== "unavailable"
      ) {
        row.cards.forEach((card) => {
          if (card !== "empty") {
            if (!playerHand[card]) {
              playerHand[card] = 1;
            } else {
              playerHand[card]++;
            }
          }
        });

        const score = calculateScore(playerHand);

        updateGameData(gameName, `players/${currPlayer}/hand`, playerHand);
        setGameData(gameName, `players/${currPlayer}/score`, score);
        setGameData(gameName, `table/rows/${rowId}/status`, "unavailable");
        setGameData(gameName, `table/rows/${rowId}/cards`, null);
      }
    });
};

export const updatePlayerOrder = (gameName, action) => {
  gamesRef
    .child(gameName)
    .once("value")
    .then((snapshot) => {
      const orderArray = snapshot.child("playerOrder").val().split(",");
      let temp = orderArray.shift();
      if (action !== "takeRow") {
        orderArray.push(temp);
      } else {
        setGameData(gameName, `/players/${temp}/rowTaken`, true);
      }

      if (orderArray.length === 0) {
        const numPlayers = snapshot.child("numPlayers").val();
        if (snapshot.child("lastRound").val()) {
          let highScore = 0;
          let winner = [];
          const players = snapshot.child("players").val();
          players.forEach((player) => {
            if (player.score === highScore) {
              winner.push(player.id); // handle ties
            } else if (player.score > highScore) {
              highScore = player.score;
              winner = [];
              winner.push(player.id);
            }
          });

          setGameData(gameName, "winner", winner.join(""));
        } else {
          for (let i = 0; i < numPlayers; i++) {
            orderArray.push(temp);
            temp = (temp % numPlayers) + 1;

            // reset their rowTaken value
            setGameData(gameName, `/players/${temp}/rowTaken`, null);
          }

          // set all rows back to empty
          updateGameData(
            gameName,
            "table/rows",
            emptyGameData[numPlayers].table.rows
          );
        }
      }
      setGameData(gameName, "playerOrder", orderArray.join(","));
    });
};
*/
