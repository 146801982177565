import React from "react";
import { Tooltip } from "rmwc";
import { orderHand } from "./utils";
import "./colors.css";
import "./Hand.css";

const displayCards = (cards, negative = false) =>
  cards.map((card, index) => (
    <div key={index} className={`card ${card}`}>
      {card === "plus2" && "+2"}
    </div>
  ));
const displayHand = (hand) =>
  hand.map((set, index) => {
    let negative = index >= 3 && set[0] !== "plus2";
    let scoreValue = 0;
    switch (set.length) {
      case 1:
        scoreValue = 1;
        break;
      case 2:
        scoreValue = 3;
        break;
      case 3:
        scoreValue = 6;
        break;
      case 4:
        scoreValue = 10;
        break;
      case 5:
        scoreValue = 15;
        break;
      default:
        scoreValue = 21;
    }
    if (set[0] === "plus2") {
      scoreValue = 2 * set.length;
    }
    return (
      <Tooltip
        content={`Set ${negative ? "cost" : "value"} ${scoreValue}pts`}
        showArrow
      >
        <div
          key={index}
          className={`card-set ${negative ? "negative" : "positive"}`}
        >
          {displayCards(set, negative)}
        </div>
      </Tooltip>
    );
  });

const Hand = ({ hand }) => {
  let orderedHand = orderHand(hand);
  return <div className="hand-container">{displayHand(orderedHand)}</div>;
};

export default Hand;
