import React, { useState } from "react";
import {
  Card,
  Theme,
  Icon,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
  TextField,
  Badge,
} from "rmwc";
import { updatePlayerName } from "./databaseActions.js";
import Hand from "./Hand";
import "./Player.css";

const Player = ({ data, playerTurn }) => {
  const [tempName, setTempName] = useState(data.name);
  const [openDialog, setOpenDialog] = useState(false);

  const handleSaveName = () => {
    if (tempName.length > 0) {
      const gameName = window.location.pathname.replace(/\//gi, "");
      updatePlayerName(gameName, data.id, tempName);
    } else {
      setTempName(data.name);
    }
  };

  let cardTheme = ["primaryBg", "onPrimary"];
  if (parseInt(playerTurn) === data.id) {
    cardTheme = ["secondaryBg", "onSecondary"];
  }
  if (data.status === "rowTaken") {
    cardTheme = "disabled";
  }

  let textField;
  return (
    <>
      <Dialog
        open={openDialog}
        onOpen={() => {
          textField.querySelector("input").select();
        }}
        onClose={(evt) => {
          setOpenDialog(false);
        }}
        onClosed={(evt) => {
          if (evt.detail.action === "accept") {
            handleSaveName();
          } else {
            setTempName(data.name);
          }
        }}
      >
        <DialogTitle>Dialog Title</DialogTitle>
        <DialogContent>
          <TextField
            className="input-name"
            label="Enter new name"
            maxLength={20}
            value={tempName}
            ref={(el) => (textField = el)}
            onChange={(evt) =>
              setTempName(evt.currentTarget.value.replace(/[^a-zA-Z0-9]/gi, ""))
            }
            onKeyPress={(evt) => {
              if (evt.key === "Enter" && tempName.length > 0) {
                handleSaveName();
                setOpenDialog(false);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <DialogButton action="close">Cancel</DialogButton>
          <DialogButton action="accept">Save</DialogButton>
        </DialogActions>
      </Dialog>
      <Card className="player-card">
        <Theme use={cardTheme} wrap>
          <div className="player-name">
            <span className="player-title">{data.name}</span>
            <Badge style={{ background: "hotpink" }} label={data.score} />
            <span className="player-edit" onClick={() => setOpenDialog(true)}>
              <Tooltip content="Edit player name" enterDelay={500} showArrow>
                <Icon
                  icon={{ icon: "create", size: "xsmall" }}
                  label="edit player name"
                />
              </Tooltip>
            </span>
          </div>
        </Theme>
        <Hand hand={data.hand} />
      </Card>
    </>
  );
};

export default Player;
