import React, { useState } from "react";
import AppHeader from "./AppHeader";
import Game from "./Game";
import "rmwc/dist/styles";

function App() {
  const [numPlayers, setNumPlayers] = useState(null);
  return (
    <div>
      <AppHeader numPlayers={numPlayers} setNumPlayers={setNumPlayers} />
      <Game numPlayers={numPlayers} setNumPlayers={setNumPlayers} />
    </div>
  );
}

export default App;
