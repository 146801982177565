const baseGameData = {
  2: {
    numPlayers: 2,
    player1name: "Player1",
    player1score: 1,
    player1hand: "",
    player1status: "",
    player2name: "Player2",
    player2score: 1,
    player2hand: "",
    player2status: "",
    playOrder: "1,2",
    status: "newGame",
    deck: "",
    row1: "empty,empty,empty",
    row2: "empty,empty",
    row3: "empty",
  },
  3: {
    numPlayers: 3,
    player1name: "Player1",
    player1score: 1,
    player1hand: "",
    player1status: "",
    player2name: "Player2",
    player2score: 1,
    player2hand: "",
    player2status: "",
    player3name: "Player3",
    player3score: 1,
    player3hand: "",
    player3status: "",
    playOrder: "1,2,3",
    status: "newGame",
    deck: "",
    row1: "empty,empty,empty",
    row2: "empty,empty,empty",
    row3: "empty,empty,empty",
  },
  4: {
    numPlayers: 4,
    player1name: "Player1",
    player1score: 1,
    player1hand: "",
    player1status: "",
    player2name: "Player2",
    player2score: 1,
    player2hand: "",
    player2status: "",
    player3name: "Player3",
    player3score: 1,
    player3hand: "",
    player3status: "",
    player4name: "Player4",
    player4score: 1,
    player4hand: "",
    player4status: "",
    playOrder: "1,2,3,4",
    status: "newGame",
    deck: "",
    row1: "empty,empty,empty",
    row2: "empty,empty,empty",
    row3: "empty,empty,empty",
    row4: "empty,empty,empty",
  },
  5: {
    numPlayers: 5,
    player1name: "Player1",
    player1score: 1,
    player1hand: "",
    player1status: "",
    player2name: "Player2",
    player2score: 1,
    player2hand: "",
    player2status: "",
    player3name: "Player3",
    player3score: 1,
    player3hand: "",
    player3status: "",
    player4name: "Player4",
    player4score: 1,
    player4hand: "",
    player4status: "",
    player5name: "Player5",
    player5score: 1,
    player5hand: "",
    player5status: "",
    playOrder: "1,2,3,4,5",
    status: "newGame",
    deck: "",
    row1: "empty,empty,empty",
    row2: "empty,empty,empty",
    row3: "empty,empty,empty",
    row4: "empty,empty,empty",
    row5: "empty,empty,empty",
  },
};

export default baseGameData;
