import { wordList } from "./wordList.js";
import map from "lodash/map";
import shuffle from "lodash/shuffle";

export const orderHand = (cardsString) => {
  if (!cardsString) {
    return [];
  }
  const cardsArray = [];
  let numWilds = 0;
  let numPlus2 = 0;
  map(cardsString.split(","), (card) => {
    const [cardKey, cardValue] = card.split(":");
    if (cardKey !== "plus2" && cardKey !== "wild") {
      cardsArray.push({
        cardKey,
        cardValue,
      });
    }
    if (cardKey === "wild") {
      numWilds = cardValue;
    }
    if (cardKey === "plus2") {
      numPlus2 = cardValue;
    }
  });
  cardsArray.sort((a, b) => b.cardValue - a.cardValue);

  const orderedHand = [];
  //if there's sets >= 6 cards, place them as a separate set
  while (cardsArray.length > 0) {
    const largestCards = cardsArray[0];
    if (largestCards.cardValue >= 6) {
      const newSet = [];
      for (let i = 0; i < largestCards.cardValue; i++) {
        newSet.push(largestCards.cardKey);
      }
      orderedHand.push(newSet);
      cardsArray.shift();
    } else {
      break;
    }
  }

  // alocate wilds such that they make a set size no larger than 6
  while (cardsArray.length > 0) {
    const largeSet = [];
    const largestCards = cardsArray.shift();
    for (let i = 0; i < largestCards.cardValue; i++) {
      largeSet.push(largestCards.cardKey);
    }

    while (largeSet.length < 6 && numWilds > 0) {
      largeSet.unshift("wild");
      numWilds--;
    }
    orderedHand.push(largeSet);
  }

  //store array of +2 cards
  if (numPlus2 > 0) {
    const plus2Cards = [];
    for (let i = 0; i < numPlus2; i++) {
      plus2Cards.push("plus2");
    }
    orderedHand.push(plus2Cards);
  }

  return orderedHand;
};

const getScore = (set) => {
  switch (set.length) {
    case 1:
      return 1;
    case 2:
      return 3;
    case 3:
      return 6;
    case 4:
      return 10;
    case 5:
      return 15;
    default:
  }
  if (set.length >= 6) {
    return 21;
  }
};

export const calculateScore = (hand) => {
  let score = 0;
  let colorCount = 0;
  const orderedHand = orderHand(hand);

  map(orderedHand, (set) => {
    if (set[0] !== "plus2") {
      if (colorCount < 3) {
        score += getScore(set);
      } else {
        score -= getScore(set);
      }
      colorCount++;
    } else {
      score += 2 * set.length;
    }
  });

  return score;
};

export const randomGameName = () => {
  const words = shuffle(wordList);
  return words.pop();
};
