import React from "react";
import { Tooltip } from "rmwc";
import map from "lodash/map";
import "./RowHouses.css";

const RowHouses = ({ rows, handleRowClick, placeCard }) => {
  return map(rows, (row, index) => {
    let tooltipContent;
    let status;
    const rowValues = !row ? [] : row.split(",");

    if (rowValues.length === 0) {
      status = "unavailable";
    } else {
      if (rowValues[0] === "empty") {
        status = "empty";
      } else if (rowValues[rowValues.length - 1] !== "empty") {
        status = "full";
      } else {
        status = "available";
      }
    }

    if (!placeCard) {
      switch (status) {
        case "available":
        case "full":
          tooltipContent = "Select row to collect cards";
          break;
        case "unavailable":
          tooltipContent = "Cards have already been taken from this row";
          break;
        default:
          tooltipContent = "There are no cards in this row";
          break;
      }
    } else {
      switch (status) {
        case "available":
        case "empty":
          tooltipContent = "Select row to place card";
          break;
        case "full":
        case "unavailable":
          tooltipContent = "Cannot place card in this row";
          break;
        default:
          tooltipContent = "Row to place cards in";
          break;
      }
    }
    return (
      <Tooltip key={index} content={tooltipContent} showArrow>
        <div
          className={"house-row " + status}
          onClick={() => {
            if (status !== "unavailable") {
              handleRowClick(index);
            }
          }}
        >
          <div className="house-card house-marker">
            {status === "available" && ">"}
            {status === "empty" && ">"}
            {status === "full" && ">"}
            {status === "unavailable" && "X"}
          </div>
          {map(rowValues, (card, index) => (
            <div
              key={index}
              className={"house-card " + (card === "" ? "empty" : card)}
            ></div>
          ))}
        </div>
      </Tooltip>
    );
  });
};

export default RowHouses;
